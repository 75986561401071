import React from 'react';
import ReactDom from 'react-dom';
import * as Sentry from '@sentry/react';
import PropTypes from 'prop-types';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { initializeSentry } from 'common/components/Sentry/Sentry';

import { initialValues, validationSchema } from 'apps/commerce/common/checkout/constants';
import ToastProvider from 'apps/commerce/common/checkout/context/toast/ToastProvider';
import PopupCheckout from '../../../apps/commerce/popup_checkout/PopupCheckout';

function FormContainer({ children, values: { externalReference } }) {
  const methods = useForm({
    mode: 'onChange',
    defaultValues: {
      ...initialValues,
      ...(externalReference && Object.keys(externalReference).length > 0 && { externalReference }),
    },
    resolver: yupResolver(validationSchema),
  });

  return <FormProvider {...methods}>{children}</FormProvider>;
}

function initPopupCheckout(link) {
  initializeSentry({ tags: { team: 'Commerce', component: 'popup-checkout' } });

  document.body.classList.add('sage-excluded');

  // determine the next popup checkout identifier
  const identifier = document.querySelectorAll('[id^="popup-checkout-"]').length + 1;

  // wrap the link in a span so we have a target to render the react component into
  link.outerHTML = `<span key='popup-checkout-${identifier}' id='popup-checkout-${identifier}'>${link.outerHTML}</span>`;

  // find the wrapper div
  const wrapper = document.querySelector(`#popup-checkout-${identifier}`);

  // extract the offer token from the hash
  const offerToken = link.href.split('#').pop().replace('popup_checkout_', '');

  // determine if we are in the theme editor
  const isEditing = !!window.Kajabi?.theme?.editor;

  const initialValuesFromHtmlNode = () => {
    if (!link.dataset.popupCheckout) return {};

    try {
      return JSON.parse(
        atob(link.dataset.popupCheckout), // base64 decode data attribute
      );
    } catch {
      return {};
    }
  };

  // render the react component into the wrapper span
  ReactDom.render(
    <Sentry.ErrorBoundary>
      <FormContainer values={initialValuesFromHtmlNode()}>
        <ToastProvider>
          <PopupCheckout el={link.outerHTML} offerToken={offerToken} enableModal={!isEditing} />
        </ToastProvider>
      </FormContainer>
    </Sentry.ErrorBoundary>,
    wrapper,
  );
}

document.addEventListener('DOMContentLoaded', () => {
  document.querySelectorAll('a[href^="#popup_checkout"]').forEach((link) => {
    initPopupCheckout(link);
  });
});

document.addEventListener('kjb:popupCheckout:init', ({ detail: { link } }) => {
  initPopupCheckout(link);
});

FormContainer.defaultProps = {
  values: {},
};

FormContainer.propTypes = {
  children: PropTypes.node.isRequired,
  values: PropTypes.shape({
    externalReference: PropTypes.shape({}),
  }),
};
